/* ImageUpload.css */

.image-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

}

.dropzone {
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;
  border: 2px dashed #cccccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.dropzone p {
  margin: 0;
  font-size: 14px;
}

.selected-image {
  min-width: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 150px;
  max-height: 150px;
}

.selected-image img {
  cursor: pointer;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 4px;
}

.selected-image .percentage {
  font-size: 11px;
  position: relative;
  bottom: 104px;
  left: 52px;
}

.selected-image .botao-remover-arquivo {
  bottom: 177px;
  right: -17px;
  float: right;
}

.selected-image .erro-upload {
  font-size: 11px;
  color: #ff0a0a;
  position: relative;
  top: -38px;
  left: 27px;
}

.btn-add-usuario-projeto {
  position: relative;
  float: right;
  right: 27px;
  top: -33px;
  right: 0px;
}

.MuiTabs-flexContainer {
  align-items: start;
}

.alerta-ativar-empresa {
  position: relative;
}

.alerta-ativar-empresa button {
  position: absolute;
  float: right;
  right: 6px;
  top: 6px;
}

.btn-pagar-cartao {
  position: relative;
  float: right;
}

.img-peril {
  object-fit: cover;
}

.upload-image-perfil {
  object-fit: cover;
  position: absolute;
  width: 74px;
  height: 90px;
  border: solid 1px;
  top: 3px;
  opacity: 0;
}